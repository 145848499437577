@font-face {
    font-family: ButlerUltraLight;
    src: url("../fonts/Butler_Ultra_Light.otf") format("opentype");
    font-weight:300;
}
@font-face {
    font-family: Butler;
    src: url("../fonts/Butler_Light.otf") format("opentype");
    font-weight:400;
}
@font-face {
    font-family: ButlerBold;
    font-weight:500;
    src: url("../fonts/Butler_Bold.otf") format("opentype");
}
@font-face {
    font-family: ButlerExtraBold;
    font-weight:700;
    src: url("../fonts/Butler_ExtraBold.otf") format("opentype");
}
@import url('https://fonts.googleapis.com/css?family=Hind+Madurai:300,400,600');
@import 'node_modules/reset-css/reset.scss';
@import 'node_modules/jeet/scss/index';
@import 'node_modules/sass-mq/_mq.scss';
@import 'node_modules/sass-rem/_rem.scss';
@import "node_modules/compass-mixins/lib/_compass.scss";

@import "layouts/tinymce";

$beige: #e9dbcd;
$darkbrown: #352e28;
$darkgrey: #4f4c48;
$lightgrey: #eaeaea;
$yellow: #f3d549;
$blue: #0fa1fd;
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;
$margin: 25px;

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

html {
  box-sizing: border-box !important;
  font-size: 16px;
  font-family: 'Hind Madurai', sans-serif;
  font-weight:300;
}
*, *:before, *:after {
  box-sizing: inherit !important;
}

body { background:$beige;
  @include mq($from:mobile) {
    @include rem(font-size, 16px);
  }
  @include mq($from:tablet) {

  }
  @include mq($from:desktop) {
    @include rem(font-size, 22px);
  }
  @include mq($from:wide) {

  }
}
a { color:$darkbrown; }
img { max-width:100%; height:auto; }
h1, h2, h3 { }
h1 { font-family: 'ButlerBold'; @include fluid-type($min_width, $max_width, 34px, 60px); text-align: center; text-transform: uppercase;
@include mq($from:mobile) {
    margin-bottom:30px;
    margin-top:60px;
}
@include mq($from:desktop) {
    margin-bottom:55px;
    margin-top:55px;
  }
}
h2 { font-family: 'Butler'; display: block; padding:0; @include fluid-type($min_width, $max_width,28px, 33px);
  @include mq($from:mobile) {
      margin:70px 0 0 0;
      padding:2px;
  }
  @include mq($from:desktop) {
      margin:70px 0 10px 0;
  }
}
h2:first-child { margin:1px 0 0 0; }
h2 { line-height:1.5em; }
h3 { font-family: 'Butler'; @include fluid-type($min_width, $max_width, 28px, 30px); margin-bottom:10px; padding:2px 0 2px 0;  }
h4 { text-transform: uppercase; margin-bottom:10px; @include fluid-type($min_width, $max_width, $min_font, 24px); }
table { width: 100%; }
.page-header { margin:25px 0 25px 0;  }
tr:nth-of-type(odd) { background: #f4f4f4 !important; }
tr:nth-of-type(even) { background: white !important; }

.collapsed tr:nth-of-type(odd) { background: white !important; }
.collapsed tr:nth-of-type(even) { background: white !important; }
th { color: white;  background: $darkgrey; padding: 17px; /*border: 1px solid #ccc;*/ text-align: left; }
td {  outline: none !important; }
.hide_mob {
  @include mq($until:tablet) {
      display:none !important;
  }
}
.show_mob {
  @include mq($from:tablet) {
      display:none !important;
  }
}
.show_tablet {
    @include mq($from:desktop) {
        display:none !important;
  }

}
.hide_tablet {
    @include mq($until:desktop) {
        display:none !important;
  }
}
.module { margin: 0 0 40px 0;  }
strong { font-weight:600; }

.cta-btn {   display: inline-block;   text-transform:uppercase; margin-bottom:10px; color:$darkgrey;
  @include mq($from:mobile) {
    @include column(1/1);
    font-weight:600;
    background:$yellow;text-align:center;
    padding:20px;
  }
  @include mq($from:desktop) {
    @include column(1/2);
    font-weight:inherit;
    border:$yellow 3px solid;
    text-align:center;
    background:none;
    padding:10px 20px 10px 20px;
  }
}
.cta-btn-center { @include center(400px); padding:10px 20px 10px 20px; text-transform:uppercase; margin-bottom:10px; color:$darkgrey; background:white; text-align:center; border:$yellow 3px solid; text-decoration:none; }
.hundred { width:100%; }
.cta-btn { text-decoration: none;  }
.cta-btn:last-child { margin:0; }
.read-more-cta { background:white; border-bottom:5px solid $yellow; padding:20px; display: inline-block;  text-align:center; width:300px; }
.read-more-cta { text-decoration: none;  }

.tomt-outer { background-image:url('../images/fjellsteggen-tomt-bg-2.jpg'); background-repeat:no-repeat; background-size:cover; border: #f7f7f7 solid 3px; width: 100%; display: block;
  @include mq($from:mobile) {
padding:0;
  }
  @include mq($from:desktop) {
    padding: 30px;
  }
}
.tomt-inner {
  @include mq($from:mobile) {
    padding:10;
      }
      @include mq($from:desktop) {
        background: white; border: #f3d549 solid 3px; padding: 50px; width: 75%; margin: 0 auto 0 auto;
      }
}

/*.cta-btn:nth-child(1) { margin-bottom:10px; }*/
section, footer, main, .info-outer, .module, .box-outer, .property-group, .box-inner { @include clearfix(); }
section {
  @include mq($from:mobile) {
    margin-bottom:30px;
  }
  @include mq($from:desktop) {
    margin-bottom:150px;
  }
}
.post-type-archive-hytter {
  section {
    @include mq($from:mobile) {
      margin-bottom:30px !important;
    }
    @include mq($from:desktop) {
      margin-bottom:40px !important;
    }
  }
}
.tips {
  @include mq($from:mobile) {
    margin-bottom:40px;
  }
  @include mq($from:desktop) {
    margin-bottom:120px;
  }
}
.constrainer { /*@include center(1400px); */
  @include mq($from:mobile) {
    @include center(100%);

  }
  @include mq($from:tablet) {
    @include center(95%);
  }
  @include mq($from:desktop) {

  }
  @include mq($from:wide) {

    }
}
.content-constrainer {
  @include mq($from:mobile) {
    @include center(95%);

  }
  @include mq($from:tablet) {

  }
  @include mq($from:desktop) {
    @include center(1400px, 20px);
  }
  @include mq($from:wide) {

  }
}

.content-wrapper .main-text {
  @include mq($from:mobile) {
    @include move();
    margin-bottom:$margin;
  }
  @include mq($from:desktop) {
    @include column(5/7);
    padding-right:100px;

  }
}
.content-wrapper aside {
  @include mq($from:mobile) {
    @include move();
  }
  @include mq($from:desktop) {
    @include column(2/7);
  }
}

.content-wrapper-cabin .article-box-outer {
  @include mq($from:mobile) {
    @include column(1/1);
  }
  @include mq($from:desktop) {
    @include column(1/2);
  }
}
.content-wrapper-cabin section { margin:0 0 35px 0; }
.content-wrapper-cabin .other-cabins { margin-top:120px; }
.slideshow-pic {
  @include column(1/2, $cycle:2);

  @include mq($from:mobile) {
    margin-bottom:9px;
  }
  @include mq($from:desktop) {
    margin-bottom:40px;
  }
}
.slideshow-pic img { display: block;}
aside .external-links {  background:$beige;
  @include mq($from:mobile) {
    padding:30px;
  }
  @include mq($from:desktop) {
    padding:60px;
  }
}
aside .internal-links .cta-btn { width:100%; margin-bottom:6px; }
.content-wrapper-cabin aside .external-links { min-height:210px; }
aside .article-box-outer {
  @include mq($from:mobile) {
    margin-bottom:6px;
  }
  @include mq($from:desktop) {
    margin-bottom:$margin;
  }
}
aside .article-box-inner ul { margin:0; padding:0; }
aside .article-box-inner ul li { background:url(../images/brown-circle.svg) left no-repeat; background-size:6px 6px; padding: 0 0 0 20px; margin: 0; }

main { background:white; line-height:1.6em;
  @include mq($from:mobile) {
    padding-bottom:40px;
  }
  @include mq($from:desktop) {
    padding-bottom:120px;
  }
}
main ul { margin: 0; padding: 0 0 0 10px; }
main ul li { background:url(../images/yellow-circle.svg) no-repeat; background-position:0px 16px; background-size:6px 6px; padding: 0 0 0 25px; margin: 0 0 10px 0;
  @include mq($from:mobile) {
    background-position:0px 10px;
  }
  @include mq($from:desktop) {
    background-position:0px 15px;
  }
}
main p { margin: 0 0 0 0; padding: 15px 0 15px 0;  }
.article {  margin-bottom:4%;
  @include mq($from:mobile) {
    height:auto;
  }
  @include mq($from:tablet) {

  }
  @include mq($from:desktop) {
    height:295px;

  }
  @include mq($from:wide) {

  }
}
.article a { text-decoration: none;  }
.articles-outer h2 {
  @include mq($from:mobile) {
    margin:0 0 20px 0;
    padding:4px 0 4px 0;
  }
  @include mq($from:desktop) {
    margin:0;
  }
}
.intro-text-outer { margin-bottom: 0px; }
.intro-text-inner {   font-family: 'ButlerUltraLight'; text-align:center; @include fluid-type($min_width, $max_width, 22px, 40px);
@include mq($from:mobile) {
  line-height:1.40em;
  @include center(80%);
}
@include mq($from:tablet) {
  }
@include mq($from:desktop) {
  line-height:1.55em;
  @include center(1000px);
}
@include mq($from:wide) {


}
}
.home .intro-text-inner p { padding:5% 0 2%; }
.intro-text-inner p {
  @include mq($from:mobile) {
    padding:0 0 40px 0;
  }
  @include mq($from:desktop) {
    padding:0 0 90px 0;
  }
}
.aktuelt-wrapper {}
.aktuelt { border-bottom:$lightgrey 1px solid; padding:20px 0 20px 0; }
.aktuelt .aktuelt-dato { color:grey; background:#f3d549; color:white; display:inline; padding:0px 7px 0px 7px; border-radius:6px; @include rem(font-size, 15px); }
.kampanje-wrapper { @include clearfix(); }
.kampanje { margin-bottom:40px; border-bottom:$lightgrey 1px solid; padding-bottom:40px; @include clearfix(); }
.kampanje-pic, .kampanje-text-wrapper {
  @include mq($from:mobile) {
    @include column(1/1);
  }
  @include mq($from:desktop) {
    @include column(1/2);
  }
}
.kampanje .kampanje-pic img { display:block; }
.kampanje .kampanje-header { margin:20px 0 20px 0; @include clearfix(); }
.kampanje .kampanje-header h2 { font-family: 'Butler'; @include fluid-type($min_width, $max_width, 28px, 30px); margin-bottom:10px; padding:2px 0 2px 0; }
.kampanje .kampanje-text { @include clearfix(); font-size: 19px; line-height: 1.6;}
.kampanje .kampanje-text-small { @include rem(font-size, 15px); }
.kampanje .liste { @include clearfix(); margin-top:10px; }
.kampanje .liste .facts { @include rem(font-size, 15px); line-height:1.6em; }
.kampanje .kampanje-price { @include clearfix(); margin-top:20px;  }
.kampanje .kampanje-read-more { @include clearfix(); margin-top:20px; font-size: 19px; }
.kampanje .kampanje-price span {  background:$yellow;  padding:7px 20px 7px 20px; display:inline-block; border-radius: 3px 3px 25px 3px; }

.boldie { font-family: 'ButlerExtraBold'; color:$yellow; }
.boldieh { font-weight: 600; }
.underline { text-decoration:underline; }
.quote { background:url(../images/topografi-trans.jpg) repeat; color:$darkbrown; min-height:200px; margin:25px 0 25px 0; line-height:1.5em;
  @include mq($from:mobile) {
    @include rem(font-size, 16px);
    padding:20px 30px 20px 30px;
  }

  @include mq($from:desktop) {
    @include rem(font-size, 24px);
    padding:40px 50px 40px 50px;
  }
}
.quote span { margin:0 0 45px 0; }

.cabin-quote {
  background:url(../images/topografi-trans.jpg) repeat; color:$darkbrown; min-height:200px; margin:25px 0 25px 0; line-height:1.5em;
  @include mq($from:mobile) {
    @include rem(font-size, 18px);
    padding:20px 30px 20px 30px;
    font-family: 'Hind Madurai', sans-serif;
  }

  @include mq($from:desktop) {
    @include rem(font-size, 35px);
    padding:80px 80px 80px 80px;
    font-family: 'ButlerUltraLight';
  }
}

.cabin-quote span { margin:0 0 45px 0; @include rem(font-size, 18px); font-family: 'Hind Madurai', sans-serif; }
.cabin-quote p { padding:0 0 25px 0; }
.cabin-quote .cabin-quote-author { text-align:right; }

.article .article-header {
  @include mq($from:mobile) {
    position: static;
  }

  @include mq($from:desktop) {
    position: relative;
    bottom:70px;
    background:white; height:295px; width:90%; margin:0 auto; padding:15px 30px 30px 30px; transition: .5s all 0.25s;
  }
}
.article .article-header-mob {
    background:$darkbrown;
    margin:0 auto;
    position: relative;
    bottom:20px;
    position: static;
    width:100%;
    height:auto;
    padding:15px;
    color:white;
    text-transform:uppercase;
  }
.article .article-header .article-text { line-height:1.5em; @include rem(font-size, 16px); }
.article .article-header .article-text span, .article .article-header .article-text p { display:block; clear:both; }
.article .article-header .article-text span { font-weight: 400; margin-top:15px;  }
.article .article-header-mob .article-header-title h3 {
margin:0; padding: 0;
}
.article:hover .article-header { bottom:260px; padding:35px 30px 30px 30px; transition: padding-top, bottom 0.2s ease-in;  box-shadow: 0px -7px 0px 0px $yellow; transition-delay: 0s; }
.article:hover img {

  @include mq($from:desktop) {
  transition: .5s all 0.25s;
  }
}
.article .article-header-title h3 { padding:0; margin: 0 0 0px 0; font-family: 'Hind Madurai', sans-serif; @include rem(font-size, 22px); /*@include fluid-type($min_width, $max_width, 14px, 18px);*/ }
.article .article-pic {
  @include mq($from:mobile) {
    position: static; height:100%;
  }
  @include mq($from:desktop) {
    position:relative;
  }
}
.article .article-pic img {

  @include mq($from:mobile) {
    display: block;
  }
      @include mq($from:desktop) {
        position: absolute; transition: opacity .5s ease-in-out;
       left: 0;
       top:35px;
      }
  }
.cut {
  background: white;
  position: relative;
  z-index: 1;
  @include mq($from:mobile) {

  }
  @include mq($from:tablet) {

  }
  @include mq($from:desktop) {

  }
  @include mq($from:wide) {

  }
}
.cut:before /*, .quote:after */{
  background: inherit;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
  @include mq($from:mobile) {
    border-top:$yellow solid 5px;
  }
  @include mq($from:desktop) {
    border-top:$yellow solid 8px;
  }

}
.cut:before {
  top: 0;
  -webkit-transform: skewY(3deg);
          transform: skewY(3deg);
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
}

.home .cut { margin: 130px 0 0 0; }

.cut {
  @include mq($from:mobile) {
    margin: 50px 0 0 0;
    padding: 0 0 3% 0;
  }
  @include mq($from:desktop) {
    margin: 126px 0 0 0;
    padding: 1% 0px 3% 0px;
  }
}
.bestill-prospekt .bestill-prospekt-left {
  @include mq($from:mobile) {
    @include column(1/1);
  }
  @include mq($from:desktop) {
    @include column(2/3);
  }
}
.bestill-prospekt .bestill-prospekt-right {
  @include mq($from:mobile) {
    @include column(1/1);
  }
  @include mq($from:desktop) {
    @include column(1/3);
  }
}
.info-outer { background:#f4f4f4;  @include clearfix();
  @include mq($from:mobile) {
    margin:40px 0 40px 0;
  }
  @include mq($from:desktop) {
    margin:40px 0 100px 0;
  }
}
.info-outer .info-inner { @include clearfix();
  @include mq($from:mobile) {
    padding:20px;
  }
  @include mq($from:desktop) {
    padding:50px;
  }
}
.info-outer .info-inner .info-icon { float: left;
  @include mq($from:mobile) {
    clear:both;
    width:100%;
    margin-bottom:$margin;
  }
  @include mq($from:desktop) {
    clear:none;
    width:10%;
    margin:0;
  }
}
.info-outer .info-inner .info-icon-inner { @include rem(font-size, 70px); line-height:67px; width:100px; height:100px; margin:0 auto; background:white;  border-radius:100px; padding:20px;  text-align:center; font-weight:600; }
.info-outer .info-inner .info-text { float: left;
  @include mq($from:mobile) {
    clear:both;
    width:100%;
  }
  @include mq($from:desktop) {
    clear:none;
    width:90%;
    padding-left:20px;
  }
}


#Layer_1 {
  fill-opacity: 0;
  transition: fill-opacity 1s;
}

.finished * {
  fill-opacity: 1;
  fill:white;
  transition: all 700ms;
}
.double-pic-outer { @include clearfix(); position: relative; padding:20px 0 20px 0; max-height:850px;
  @include mq($from:mobile) {
    margin:10px 0 10px 0;
  }
  @include mq($from:desktop) {
    margin:50px 0 50px 0;
  }
}
.double-pic-outer:after {
  content: "";
  z-index: 1111111111;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  height:100%;
  @include align();
  padding:30px 0 30px 0;
  @include mq($from:mobile) {
    border-left: 2px solid $yellow;
  }
  @include mq($from:desktop) {
    border-left: 5px solid $yellow;
  }
}
.double-pic-outer .pic-1, .double-pic-outer .pic-2 { @include span(1/2); }
.property-selector { text-align: center; }
.property-selector h2 {
  @include mq($from:mobile) {
    margin-bottom:5px;
  }
  @include mq($from:desktop) {
    margin-bottom:0;
  }
}
.red { color:red; }
.green { color:green; }
.yellow { color:$yellow; }
ul.tabs{
      padding: 0px;
      list-style: none;
      display:block;
  @include mq($from:mobile) {
    margin: 0 0 10px 0;
  }
  @include mq($from:desktop) {
    margin: 0px 0 0 0;
  }
    }
    ul.tabs li{
      background: none;
      display: inline-block;
      cursor: pointer;
      text-indent:0;
      padding: 7px 25px 7px 25px;
      border-radius: 30px;
      margin:0 10px 0 0;
      background:$lightgrey;
      @include mq($from:mobile) {
        width:100%;
        margin-bottom:5px;
      }
      @include mq($from:desktop) {
        width:inherit;
        margin-bottom:10px;
      }
    }

    ul.tabs li::before {
      content: "";
    }

    ul.tabs li.current{
      background: $yellow;
      position:relative;
    }

    ul.tabs li.current:after {
      content:'';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
      width: 0;
      height: 0;
      border-top: solid 10px $yellow;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      @include mq($until:tablet) {
        display:none;
      }
    }
    .tab-content{
      display: none;
    }

    .tab-content.current{
      display: inherit;
    }
/*ul.tabs li.test-2 { background:#a0bcd5; }
ul.tabs li.test-3 { background:#b44d97; }*/
.size-cover { margin-bottom:40px; }
.slideshow-pic .size-cover { margin-bottom:0; }
.wp-post-image { margin-bottom:0; }
.box-outer { background:$darkgrey; color: white; }
.box-outer .cta-btn { background:$yellow; }
.box-inner {
  @include mq($from:mobile) {
    padding:50px 30px 30px 30px;
  }
  @include mq($from:desktop) {
    padding:70px
  }
}
.box-inner .one {
  @include mq($from:mobile) {
    @include column(1/1);
    margin-bottom:$margin;
  }
  @include mq($from:desktop) {
    @include column(2/3, $gutter:8);
    margin-bottom:0;
  }
}
.box-inner .two {
  @include mq($from:mobile) {
    @include column(1/1);
  }
  @include mq($from:desktop) {
   @include column(1/3, $gutter:8);
  }
}
.process {
  background:url(../images/treverk.jpg) no-repeat;
  background-size:cover;
  @include mq($from:mobile) {
    @include column(1/1);
    height:auto;
  }
  @include mq($from:desktop) {
     min-height:520px;
    @include column(1/3, $cycle:3, $gutter:4);
  }
  margin-bottom:35px;
}
.process .box-inner {
  @include mq($from:mobile) {
    padding-top:50px;

  }
  @include mq($from:desktop) {
    padding:70px 40px 40px 40px;
  }
}
.box-inner h2 { font-family: 'Hind Madurai', sans-serif;  text-transform:uppercase; font-weight:600;
  @include mq($from:mobile) {
    @include rem(font-size, 20px);
    margin:0 0 5px 0;
  }
  @include mq($from:desktop) {
    @include rem(font-size, 24px);
    margin:0 0 25px 0;
  }
}
.box-inner ul {
  @include mq($from:mobile) {
    padding:0;
  }
  @include mq($from:desktop) {
   padding: 0 0 0 10px;
  }
 }
.box-inner li {
  /*@include mq($from:mobile) {
    padding-left:17px; margin-bottom:20px;
  }
  @include mq($from:desktop) {
   padding-left:17px; margin-bottom:20px;
  }*/
}
.box-inner .text { line-height:1.50em;
  @include mq($from:mobile) {
    @include rem(font-size, 15px);
  }
  @include mq($from:desktop) {
    @include rem(font-size, 19px);
  }
}
.box-outer .number { background:$yellow; padding:5px 20px 5px 20px; display: inline-block; margin-top:-10px; position: absolute; border:#f1ca15 solid 1px;
  @include mq($from:mobile) {
    padding:5px 15px 5px 15px;
     margin-left:32px;
  }
  @include mq($from:desktop) {
   padding:5px 20px 5px 20px;
    margin-left:40px;
  }
}
.facts-inner .facts {
  @include mq($from:mobile) {
    @include move();
    margin-bottom:25px;
  }
  @include mq($from:desktop) {
    @include column(1/3);
  }

}
.facts-inner .facts h3 { padding: 0 0 10px 10px;  }

.indenter { padding:0 100px 0 100px;  }
.main-container-outer { @include center(100%); }

.indent { @include center(80%); }

.home .main-pic-container-outer { margin: 0 auto; background:url(../images/Fjellsteggen-utsikt-SJ_4898-Edit-3.jpg) no-repeat; background-size:contain;  opacity: 0; width:100%; display: inline-block;  position: relative; z-index:1;

@include mq($from:mobile) {
  background-position:0 80px;
}
@include mq($from:tablet) {

}
@include mq($from:desktop) {
  background-position:0 130px;
}
@include mq($from:wide) {

  }
}
.home .main-pic-container-outer:after{

   padding-top: 57% !important;
   /* Hvis bildet er 800x600 ratio, så sett padding-top:% til å matche 600/800 = 0.75 */
   display: block !important;
   content: '' !important;

    @include mq($from:mobile) {
      padding-top: 75% !important;
    }
    @include mq($from:tablet) {

    }
    @include mq($from:desktop) {
      padding-top: 57% !important;
    }
    @include mq($from:wide) {
    }
}
.home .main-pic-container-outer .main-pic-btn-outer { position: absolute; bottom:300px; z-index:111111111111111111111111111111; @include align(horizontal); }
.home .main-pic-container-outer .main-pic-btn-inner {  }


/* Hytte-relatert */
.hytte-main-pic-container-outer { margin: 0 auto; opacity: 0; width:100%; display: inline-block;  position: relative; z-index:1;

@include mq($from:mobile) {
  background-position:0 80px;
}
@include mq($from:tablet) {

}
@include mq($from:desktop) {
  background-position:0 130px;
}
@include mq($from:wide) {

  }
}
.hytte-main-pic-container-outer:after{

  padding-top: 57% !important;
   display: block !important;
   content: '' !important;

    @include mq($from:mobile) {
 padding-top: 75% !important;

    }
    @include mq($from:tablet) {

    }
    @include mq($from:desktop) {
     padding-top: 57% !important;

    }
    @include mq($from:wide) {

    }
}
.hytte-main-pic-container-outer .hytte-main-pic-btn-outer { position: absolute; bottom:300px; z-index:111111111111111111111111111111; @include align(horizontal); }
.hytte-main-pic-container-outer .hytte-main-pic-btn-inner {  }

.single-hytter.fjellsteggen-trehytta .main-content-outer {/* margin-top:-10%;*/  transition: all 500ms; /*position:relative;*/  transition: all 500ms; position:relative; z-index:1;
  @include mq($from:mobile) {
 margin-top:-210px;transform: scale(0.95);

  }
  @include mq($from:tablet) {
    margin-top:-550px;
  }
  @include mq($from:desktop) {
    margin-top:-600px;transform: scale(0.85);

  }
  @include mq($from:wide) {
    margin-top:-800px;transform: scale(0.85);
  }
}

.single-hytter.fjellsteggen-trehytta .main-content-outer {/* margin-top:-10%;*/  transition: all 500ms; /*position:relative;*/  transition: all 500ms; position:relative; z-index:1;
  @include mq($from:mobile) {
 margin-top:-210px;transform: scale(0.95);

  }
  @include mq($from:tablet) {
    margin-top:-550px;
  }
  @include mq($from:desktop) {
    margin-top:-600px;transform: scale(0.85);

  }
  @include mq($from:wide) {
    margin-top:-800px;transform: scale(0.85);
  }
}

.single-hytter.fjellsteggen-duohytter .main-content-outer {/* margin-top:-10%;*/  transition: all 500ms; /*position:relative;*/  transition: all 500ms; position:relative; z-index:1;
  @include mq($from:mobile) {
 margin-top:-210px;transform: scale(0.95);

  }
  @include mq($from:tablet) {
    margin-top:-550px;
  }
  @include mq($from:desktop) {
    margin-top:-600px;transform: scale(0.85);

  }
  @include mq($from:wide) {
    margin-top:-800px;transform: scale(0.85);
  }
}

.single-hytter.fjellsteggen-premium-2 .main-content-outer {/* margin-top:-10%;*/  transition: all 500ms; /*position:relative;*/  transition: all 500ms; position:relative; z-index:1;
  @include mq($from:mobile) {
 margin-top:-210px;transform: scale(0.95);

  }
  @include mq($from:tablet) {
    margin-top:-550px;
  }
  @include mq($from:desktop) {
    margin-top:-600px;transform: scale(0.85);

  }
  @include mq($from:wide) {
    margin-top:-1000px;transform: scale(0.85);
  }
}



.single-hytter.fjellsteggen-premium-4 .main-content-outer {/* margin-top:-10%;*/  transition: all 500ms; /*position:relative;*/  transition: all 500ms; position:relative; z-index:1;
  @include mq($from:mobile) {
 margin-top:-210px;transform: scale(0.95);

  }
  @include mq($from:tablet) {
    margin-top:-550px;
  }
  @include mq($from:desktop) {
    margin-top:-600px;transform: scale(0.85);

  }
  @include mq($from:wide) {
    margin-top:-850px;transform: scale(0.85);
  }
}

.single-hytter.fjellsteggen-premium-1 .main-content-outer {/* margin-top:-10%;*/  transition: all 500ms; /*position:relative;*/  transition: all 500ms; position:relative; z-index:1;
  @include mq($from:mobile) {
 margin-top:-210px;transform: scale(0.95);

  }
  @include mq($from:tablet) {
    margin-top:-550px;
  }
  @include mq($from:desktop) {
    margin-top:-600px;transform: scale(0.85);

  }
  @include mq($from:wide) {
    margin-top:-850px;transform: scale(0.85);
  }
}

.single-hytter.fjellsteggen-premium-3 .main-content-outer {/* margin-top:-10%;*/  transition: all 500ms; /*position:relative;*/  transition: all 500ms; position:relative; z-index:1;
  @include mq($from:mobile) {
 margin-top:-210px;transform: scale(0.95);

  }
  @include mq($from:tablet) {
    margin-top:-550px;
  }
  @include mq($from:desktop) {
    margin-top:-600px;transform: scale(0.85);

  }
  @include mq($from:wide) {
    margin-top:-850px;transform: scale(0.85);
  }
}

.single-hytter.fjellsteggen-smart-3 .main-content-outer {/* margin-top:-10%;*/  transition: all 500ms; /*position:relative;*/  transition: all 500ms; position:relative; z-index:1;
  @include mq($from:mobile) {
 margin-top:-210px;transform: scale(0.95);

  }
  @include mq($from:tablet) {
    margin-top:-550px;
  }
  @include mq($from:desktop) {
    margin-top:-600px;transform: scale(0.85);

  }
  @include mq($from:wide) {
    margin-top:-1200px;transform: scale(0.85);
  }
}

.single-hytter.fjellsteggen-smart-1 .main-content-outer {/* margin-top:-10%;*/  transition: all 500ms; /*position:relative;*/  transition: all 500ms; position:relative; z-index:1;
  @include mq($from:mobile) {
 margin-top:-210px;transform: scale(0.95);

  }
  @include mq($from:tablet) {
    margin-top:-550px;
  }
  @include mq($from:desktop) {
    margin-top:-600px;transform: scale(0.85);

  }
  @include mq($from:wide) {
    margin-top:-500px;transform: scale(0.85);
  }
}

.single-hytter.fjellsteggen-smart-2 .main-content-outer {/* margin-top:-10%;*/  transition: all 500ms; /*position:relative;*/  transition: all 500ms; position:relative; z-index:1;
  @include mq($from:mobile) {
 margin-top:-210px;transform: scale(0.95);

  }
  @include mq($from:tablet) {
    margin-top:-550px;
  }
  @include mq($from:desktop) {
    margin-top:-600px;transform: scale(0.85);

  }
  @include mq($from:wide) {
    margin-top:-px;transform: scale(0.85);
  }
}


.single-hytter.fjellsteggen-smart-4 .main-content-outer {/* margin-top:-10%;*/  transition: all 500ms; /*position:relative;*/  transition: all 500ms; position:relative; z-index:1;
  @include mq($from:mobile) {
 margin-top:-210px;transform: scale(0.95);

  }
  @include mq($from:tablet) {
    margin-top:-550px;
  }
  @include mq($from:desktop) {
    margin-top:-600px;transform: scale(0.85);

  }
  @include mq($from:wide) {
    margin-top:-650px;transform: scale(0.85);
  }
}

.single-hytter.hyttetest .main-content-outer {/* margin-top:-10%;*/  transition: all 500ms; /*position:relative;*/  transition: all 500ms; position:relative; z-index:1;
  @include mq($from:mobile) {
 margin-top:-210px;transform: scale(0.95);

  }
  @include mq($from:tablet) {
    margin-top:-550px;
  }
  @include mq($from:desktop) {
    margin-top:-600px;transform: scale(0.85);

  }
  @include mq($from:wide) {
    margin-top:-800px;transform: scale(0.85);
  }
}

.single-hytter.single-hytter.fjellsteggen-smart-5 .whoop { transform: scale(1);  transition: all 1200ms; z-index:1111; }
.single-hytter.single-hytter.fjellsteggen-smart-3 .whoop { transform: scale(0.96);  transition: all 1200ms; z-index:1111; }
.single-hytter.single-hytter.fjellsteggen-smart-4 .whoop { transform: scale(0.96);  transition: all 1200ms; z-index:1111; }
.single-hytter.single-hytter.fjellsteggen-smart-1 .whoop { transform: scale(1);  transition: all 1200ms; z-index:1111; }
.single-hytter.single-hytter.fjellsteggen-smart-2 .whoop { transform: scale(1);  transition: all 1200ms; z-index:1111; }
.single-hytter.single-hytter.fjellsteggen-premium-2 .whoop { transform: scale(0.96);  transition: all 1200ms; z-index:1111; }
.single-hytter.single-hytter.fjellsteggen-premium-4 .whoop { transform: scale(1);  transition: all 1200ms; z-index:1111; }
.single-hytter.single-hytter.fjellsteggen-duohytter .whoop { transform: scale(1);  transition: all 1200ms; z-index:1111; }
.single-hytter.single-hytter.fjellsteggen-premium-3 .whoop { transform: scale(1);  transition: all 1200ms; z-index:1111; }
.single-hytter.single-hytter.fjellsteggen-premium-1 .whoop { transform: scale(1);  transition: all 1200ms; z-index:1111; }
.single-hytter.single-hytter.hyttetest .whoop { transform: scale(1);  transition: all 1200ms; z-index:1111; }
/* Hytte-relatert slutt */




.home .main-content-outer {/* margin-top:-10%;*/  transition: all 500ms; /*position:relative;*/  transition: all 500ms; position:relative; z-index:1;
  @include mq($from:mobile) {
    margin-top:-280px;transform: scale(0.95);
  }
  @include mq($from:tablet) {
    margin-top:-550px;
  }
  @include mq($from:desktop) {
    margin-top:-600px;transform: scale(0.85);
  }
  @include mq($from:wide) {
    margin-top:-700px;transform: scale(0.85);
  }
}

.home .whoop { transform: scale(1);  transition: all 1200ms; z-index:1111; }


.sub-pic-container-outer { margin: 0 auto; background:$yellow url(../images/fjellsteggen-rauland.jpg) no-repeat; background-size:cover; width:100%; display: block;  z-index:-111111111111111111; height:450px; position: relative;
}

.main-content-outer { transform: scale(1);
   @include mq($from:mobile) {
    margin-top:-320px;
   }

   @include mq($from:desktop) {
    margin-top:-160px;
   }

}

/*.main-pic-container-outer { @include center(100%); background-attachment:fixed; background:url(../images/bilde-top.jpg) no-repeat; background-size:100%; background-position:0 130px;  opacity: 0;
  @include mq($from:mobile) {
    height:44vh;
  }

  @include mq($from:desktop) {
    height:100vh;
  }

}*/

/*.main-pic-container-outer .main-pic-text-outer { }
.main-pic-container-outer .main-pic-text-inner { background:green; @include align(); z-index: 1000; }*/
/*.main-content-outer { position:relative;
 @include mq($from:mobile) {

    margin-top:-135px;
  }

  @include mq($from:desktop) {

    margin-top:-790px;
  }
}*/


/*.whoop { transform: scale(1);  transition: all 1200ms; }*/

.top-header { background:$darkbrown; }

.top-header {  position: absolute; width: 100%; top:-130px; z-index:1111; /*Hva gjør topp -130? husker ikke*/
  @include mq($from:mobile) {
    height:80px;
  }
  @include mq($from:tablet) {

  }
  @include mq($from:desktop) {
    height:130px;
  }
  @include mq($from:wide) {

    }
}
.top-header .logo { max-width:250px;  /*height:250px;*/   height:100%; display: none;
  @include mq($from:mobile) {
    margin:0;
  }
  @include mq($from:tablet) {

  }
  @include mq($from:desktop) {
    margin:0 auto;
  }
  @include mq($from:wide) {

    }
}

.top-header .logo svg {  padding:0;
  @include mq($from:mobile) {
    max-width:120px;
    margin: 25px 0 0 22px;
  }
  @include mq($from:desktop) {
    max-width:250px;
    margin: 32px 0 0 0;
  }
}

/* Hytte top-header start */
.top-header-hytte { background:$darkbrown; }

.top-header-hytte {  position: absolute; width: 100%; top:0; z-index:1111; /*Hva gjør topp -130? husker ikke*/
  @include mq($from:mobile) {
    height:80px;
  }
  @include mq($from:tablet) {

  }
  @include mq($from:desktop) {
    height:130px;
  }
  @include mq($from:wide) {

    }
}

.top-header-hytte .logo-hytte { max-width:250px;  /*height:250px;*/ height:100%;
  @include mq($from:mobile) {
    margin:0;
  }
  @include mq($from:tablet) {

  }
  @include mq($from:desktop) {
    margin:30px auto 0 auto;
  }
  @include mq($from:wide) {

    }
}
.top-header-hytte .logo-hytte img {  padding:0;
  @include mq($from:mobile) {
    max-width:150px;
    margin: 20px 0 0 22px;
  }
  @include mq($from:desktop) {
    max-width:250px;
    margin: 0 0 0 0;
  }
}


.top-header-hytte .top-buttons { position: absolute; right:0; top:0; height:100%; /*og animasjon fra høyre: position: absolute; right:0; top:0; height:100%; og fjerne relative og overflow på diven innenfor body*/ }
.top-header-hytte .top-buttons a {  color:white; }
.top-header-hytte .burger-wrapper { background:darken($darkbrown,5%);  height:100%; float: right; display: block; }
.top-header-hytte .burger-wrapper .hamburger-menu-text { color:white; display: block; }
.top-header-hytte .burger-wrapper a { text-decoration: none; }
.top-header-hytte .burger-wrapper .hamburger-box { margin-bottom:10px; }

.top-header-hytte .call-wrapper { height:100%;  float: right; display: block;  }
.top-header-hytte .call-wrapper .call-wrapper-inner { height:100%; width:90px; position: relative; }
.top-header-hytte .call-wrapper .call-wrapper-inner .call-button-outer { position: absolute; height:100%; width:100%; background:#637f38; }
.top-header-hytte .call-wrapper .call-wrapper-inner .call-button-inner { @include align(); }
.top-header-hytte .call-wrapper .call-icon {  height:40px; }
.top-header-hytte .call-wrapper .call-icon img { width:30px; margin: 0 auto; display: block;}
.top-header-hytte .call-wrapper-inner a { height:100%;/* display: inline-block;*/  }
/*.top-header .call-wrapper-inner a { padding:15px; height:100%; width:100%; text-decoration: none; display: block; }
.top-header .call-wrapper-inner img { top:11px; position: relative; }*/

/* Hytte top-header end */


.top-header-sub {  position: absolute; width: 100%;
  @include mq($from:mobile) {
    height:80px;
    top:30px;
  }
  @include mq($from:tablet) {

  }
  @include mq($from:desktop) {
    height:130px;
    top:100px;
  }
  @include mq($from:wide) {

    }
}
.top-header-sub .logo {   /*height:250px;*/   height:100%; display: block;
  @include mq($from:mobile) {
    margin:0;
    max-width:200px;
  }
  @include mq($from:tablet) {

  }
  @include mq($from:desktop) {
    margin:0 auto;
    max-width:350px;
  }
  @include mq($from:wide) {

    }
}

.top-header-sub .logo img {
  @include mq($from:mobile) {
    max-width:170px;
    margin:18px 0 0 20px;
  }
  @include mq($from:tablet) {

  }
  @include mq($from:desktop) {
    max-width:inherit;
  }
  @include mq($from:wide) {

    }
  }

.top-header-sub .top-buttons { position: absolute; top:0; right:0; height:100%; z-index:1000000;
  @include mq($from:mobile) {
    margin-right:10px;
  }
  @include mq($from:tablet) {

  }
  @include mq($from:desktop) {
    margin-right:5%;
  }
  @include mq($from:wide) {

    }
/*og animasjon fra høyre: position: absolute; right:0; top:0; height:100%; og fjerne relative og overflow på diven innenfor body*/
}
.top-header-sub .top-buttons a {  color:white; }
.top-header-sub .burger-wrapper {   height:100%; display: block; }
.top-header-sub .burger-wrapper .hamburger-menu-text { color:white; display: block; }
.top-header-sub .burger-wrapper a { text-decoration: none; }
.top-header-sub .burger-wrapper .hamburger-box { margin-bottom:10px; }

.top-header-sub .call-wrapper { height:100%;  float: right; display: block; display: none; }
.top-header-sub .call-wrapper .call-wrapper-inner { height:100%; width:90px; position: relative; }
.top-header-sub .call-wrapper .call-wrapper-inner .call-button-outer { position: absolute; height:100%; width:100%; background:#637f38; }
.top-header-sub .call-wrapper .call-wrapper-inner .call-button-inner { @include align(); }
.top-header-sub .call-wrapper .call-icon {  height:40px; }
.top-header-sub .call-wrapper .call-icon img { width:30px; margin: 0 auto; display: block;}
.top-header-sub .call-wrapper-inner a { background:red; height:100%;/* display: inline-block;*/  }

.top-header .top-buttons { position: absolute; right:-90px; top:0; height:100%; /*og animasjon fra høyre: position: absolute; right:0; top:0; height:100%; og fjerne relative og overflow på diven innenfor body*/ }
.top-header .top-buttons a {  color:white; }
.top-header .burger-wrapper { background:darken($darkbrown,5%);  height:100%; float: right; display: block; }
.top-header .burger-wrapper .hamburger-menu-text { color:white; display: block; }
.top-header .burger-wrapper a { text-decoration: none; }
.top-header .burger-wrapper .hamburger-box { margin-bottom:10px; }

.top-header .call-wrapper { height:100%;  float: right; display: block;  }
.top-header .call-wrapper .call-wrapper-inner { height:100%; width:90px; position: relative; }
.top-header .call-wrapper .call-wrapper-inner .call-button-outer { position: absolute; height:100%; width:100%; background:#637f38; }
.top-header .call-wrapper .call-wrapper-inner .call-button-inner { @include align(); }
.top-header .call-wrapper .call-icon {  height:40px; }
.top-header .call-wrapper .call-icon img { width:30px; margin: 0 auto; display: block;}
.top-header .call-wrapper-inner a { height:100%; }
/*.top-header .call-wrapper-inner a { padding:15px; height:100%; width:100%; text-decoration: none; display: block; }
.top-header .call-wrapper-inner img { top:11px; position: relative; }*/

.top-header .call-wrapper-desktop { height:100%;  float: right; display: block; background: rgb(99, 127, 56); width:100px; text-align:center; }
.top-header .call-wrapper-desktop a { padding:25px 5px 5px 5px; height:100%; text-decoration: none;  display:block; width:100%;
  @include mq($from:tablet) {
    padding:10px 5px 5px 5px;
    font-size:13px !important;
    line-height:16px;
  }
  @include mq($from:desktop) {
    padding:25px 5px 5px 5px;
    font-size:16px !important;
    line-height:20px;
  }
}
.top-header .call-wrapper-desktop .call-icon {
  @include mq($from:tablet) {
    height:30px;
  }
  @include mq($from:desktop) {
    height:40px;
  }
}
.top-header .call-wrapper-desktop .call-icon img {  margin: 0 auto; display: block;
  @include mq($from:tablet) {
    width:25px;
  }
  @include mq($from:desktop) {
    width:30px;
  }
}

.home .overlay { z-index: 1001; position: fixed; background: rgba(53, 46, 40, 0.95); left: 0; width: 100%; height: 100%; opacity: 0; visibility: hidden; transition: opacity .35s, visibility .35s, height .35s; overflow: hidden;
  @include mq($from:mobile) {
    top: 80px;
  }
  @include mq($from:desktop) {
    top: 130px;
  }
}
.single-hytter .overlay { z-index: 1001; position: fixed; background: rgba(53, 46, 40, 0.95); left: 0; width: 100%; height: 100%; opacity: 0; visibility: hidden; transition: opacity .35s, visibility .35s, height .35s; overflow: hidden;
  @include mq($from:mobile) {
    top: 80px;
  }
  @include mq($from:desktop) {
    top: 130px;
  }
}
.single-hytter .overlay nav { top:30%; }
.overlay { z-index: 1001; position: fixed; background: rgba(53, 46, 40, 0.95); left: 0; width: 100%; height: 100%; opacity: 0; visibility: hidden; transition: opacity .35s, visibility .35s, height .35s; overflow: hidden; top:0;
  @include mq($from:mobile) {
    background: rgba(53, 46, 40, 1);
  }
  @include mq($from:desktop) {
    background: rgba(53, 46, 40, 0.95);
  }
}
.overlay.open { opacity: 1; visibility: visible; height: 100%; }
.overlay nav { position: relative; height: 50%; top: 42%; transform: translateY(-50%); text-transform: uppercase; text-align: center;
  @include mq($from:mobile) {
    top: 42%;
  }
  @include mq($from:desktop) {
    top: 44%;
  }
}
.home .overlay nav { position: relative; height: 50%; top: 42%; transform: translateY(-50%); text-transform: uppercase; text-align: center;
  @include mq($from:mobile) {
    top: 32%;
  }
  @include mq($from:desktop) {
    top: 32%;
  }
}
.overlay ul { list-style: none; padding: 0; margin: 0 auto; display: inline-block; position: relative; height: 100%; width: 100%; }
.overlay ul li { display: block; position: relative; opacity: 0;
  @include mq($from:mobile) {
    margin: 0;
  }
  @include mq($from:desktop) {
    margin: 30px 0 30px 0;
  }
}
.overlay ul li a { display: block; position: relative; color: white; text-decoration: none; overflow: hidden; font-family: 'ButlerUltraLight';
@include mq($from:mobile) {
  border-bottom:black 1px solid;
  padding: 15px 0 15px 0;
  @include rem(font-size, 20px);
}
@include mq($from:desktop) {
  border:none;
  padding: 0;
  @include rem(font-size, 37px);
}
}
.overlay ul li.menu-kampanjer a { color:#85bcff;  }
.overlay ul li a:after { content: ''; position: absolute; top: 50%; left: 43.5%; width: 0%; transform: translateX(-50%); height: 3px; background: white; transition: .35s; }
.overlay ul li a:hover, .overlay ul li a:focus, .overlay ul li a:active { color:$yellow; }
/*.overlay ul li a span:hover {
  @include mq($from:mobile) {

  }
  @include mq($from:desktop) {
    background: $yellow; color:$darkbrown;
  }
}*/
.overlay.open li { animation: fadeInRight .3s ease forwards; animation-delay: .10s; }
.overlay.open li:nth-of-type(2) { animation-delay: .20s; }
.overlay.open li:nth-of-type(3) { animation-delay: .30s; }
.overlay.open li:nth-of-type(4) { animation-delay: .40s; }
.overlay.open li:nth-of-type(5) { animation-delay: .50s; }
.overlay.open li:nth-of-type(6) { animation-delay: .60s; }
.overlay.open li:nth-of-type(7) { animation-delay: .70s; }
.overlay.open li:nth-of-type(8) { animation-delay: .80s; }
@keyframes fadeInRight {
  0% { opacity: 0; left: 20%; }
  100% { opacity: 1; left: 0; }
}
.open-menu, .close-menu, .button { cursor: pointer; transition: transform 0.30s; }

.linolini {
  position: relative;
  text-align: center;
  @include mq($from:mobile) {
    margin-bottom:0;
  }

  @include mq($from:desktop) {
    margin-bottom:25px;
  }
}

.linolini span {
  background: white;
  padding: 0 15px 0 15px;
  position: relative;
  z-index: 1;
  display:inline-block;
}

.linolini span img {
  background:white;
  @include mq($from:mobile) {
    margin:4px 0 0 0;
    max-width:35px;
  }

  @include mq($from:desktop) {
    max-width:55px;
    margin:10px 10px 0 0;
  }

}

.linolini:before {
  background: $lightgrey;
  content: "";
  display: block;
  position: absolute;
    top: 50%;
  width: 100%;
  @include mq($from:mobile) {
    height:2px;
  }

  @include mq($from:desktop) {
    height:4px;
  }

}
.linolini:before {
  left: 0;
}
.mobile-cta-outer { margin-bottom:60px;  }
.mobile-cta-inner { background:url(../images/topografi.jpg); padding:20px; background-size:100%; @include clearfix(); }
.mobile-cta-inner .cta-btn {  opacity:0.8; }
.mobile-cta-inner .cta-btn:first-child { margin-bottom:10px; }

.desk-cta-outer { }
.desk-cta-inner { background-size:100%; @include clearfix(); }
.desk-cta-inner .cta-btn { padding:20px; border:$yellow 5px solid; @include rem(font-size, 30px); }
.home .cta-btn { @include rem(font-size, 24px); }


.story-btn { width:70%; margin:0 auto; padding:55px 0 90px 0; }
.story { z-index:11111111111; @include clearfix(); position: relative;
  @include mq($from:mobile) {
    padding:0 0 0 0;
  }
  @include mq($from:desktop) {
    margin-bottom:60px; padding-top:60px;
  }
}
.story:last-child {
  @include mq($from:mobile) {
    padding-bottom:0;
    border-bottom:$lightgrey 1px solid;
  }
  @include mq($from:desktop) {
    padding-bottom:60px;
    margin-bottom:0;
    border-bottom:none;
  }
}
.story h2 {
  @include mq($from:mobile) {
      margin:50px 0 0 0;
  }
  @include mq($from:desktop) {
      margin:25px 0 0px 0;
  }
}
/*.story .storyline-icon { margin-bottom:$margin; }*/
.story .storyline-text {
  @include mq($from:mobile) {
     @include center(85%);
  }
  @include mq($from:desktop) {
     @include center(75%);
     @include rem(font-size, 24px);
     line-height:1.7em;
  }
}
.story .storyline-text span { margin-bottom:$margin; display:block;  }
.storyline-info-outer {  float: left;
   @include mq($from:mobile) {
      width:100%;

   }
   @include mq($from:desktop) {
      width:49%;
   }
}
.storyline-buttons {
  @include mq($from:mobile) {
     @include center(85%);
     margin-bottom:55px;
  }
  @include mq($from:desktop) {
     @include center(75%);
  }
}
.storyline-pic-outer { float:left;
   @include mq($from:mobile) {
      width:100%;
   }
   @include mq($from:desktop) {
      width:51%;
   }
}
.storyline-pic-outer img { width:100%;  }
.storyline-outer {  }
.storyline-inner {
  position: relative;
  text-align: center;
}

.storyline-inner:after {
    @include mq($from:mobile) {
      display:none;
    }
    @include mq($from:desktop) {
      content: "";
      position: absolute;
      z-index: 11;
      top: 0;
      bottom: 0;
      left: 50%;
      border-left: 4px solid $lightgrey;
      transform: translate(-50%);
      display:inherit;
    }
}
.storyline-circle-start { height:40px;
    @include mq($until:desktop) {
      display:none;
    }
}
.storyline-circle-start:before { background:white; border:$lightgrey 5px solid; border-radius:25px; width:40px; height:40px; content: ""; position: relative; z-index: 122222221; top: 0; bottom: 0; left: 50%; transform: translate(-50%); display:inherit; }
.storyline-circle-end { height:40px;
    @include mq($until:desktop) {
      display:none;
    }
}
.storyline-circle-end:before { background:white; border:$lightgrey 5px solid; border-radius:25px; width:40px; height:40px; content: ""; position: relative; z-index: 122222221; top: 0; bottom: 0; left: 50%; transform: translate(-50%); display:inherit; }
.wide-pic-outer { background:black; }
.wide-pic-inner { position:relative; }
.wide-pic-inner .pic { position: relative; opacity: 0.5; }
.wide-pic-inner .wide-pic-buttons { z-index:111111111111; @include align(); }

.video-outer { }
.video-inner { position:relative; }
.video-inner .video-buttons { z-index:111111111111; @include align(); width:460px; }
.video-inner .video-buttons .cta-btn { background:$yellow; }
video {
  width: 100%    !important;
  height: auto   !important;
}

/*.wide-pic-outer {  }
.wide-pic-inner { }
.wide-pic-inner .pic { @include column(1/2);}
.wide-pic-inner .wide-pic-buttons { @include column(1/2); }
.wide-pic-inner img { border:$lightgrey 10px solid; }

.wide-pic-text-outer {  background:green; }
.wide-pic-text-inner {}*/

.buttons { width:100%; height:220px; margin-top:50px; }
.buttons-inner { @include center();  }
.buttons-inner .testknapp { background:$yellow; height:120px; display: block; float: left; @include column(1/2); line-height:3em; text-align:center; font-size:43px; }

.contact-bar {
  @include clearfix();
  @include mq($from:mobile) {
    text-align:center;
  }
  @include mq($from:desktop) {
    text-align:inherit;
  }
}
.contact-bar .contact-pic {
  @include mq($from:mobile) {

  }
  @include mq($from:desktop) {
    @include column(1/5);
  }
}
.contact-bar .contact-pic img { border-radius: 200px;
  @include mq($from:mobile) {
    max-width:50%;
  }
  @include mq($from:desktop) {
    max-width:100%;
  }
}
.contact-bar .contact-info {  margin-top:20px;

  @include mq($from:desktop) {

    @include column(2/5);
    margin-left: 15%;
  }
}
.contact-bar .contact-info div { margin-bottom:8px;  }

.contact-bar .contact-form {
line-height:1.6em;
  @include mq($from:mobile) {
  margin-top:55px;
  }
  @include mq($from:desktop) {
    margin-top:20px;
    @include column(2/5);
  }
}
.page-template-page-kontakt {
  .content-constrainer {
    max-width: 95%;
  }
  .main {
    padding-bottom: 0px;
  }
}
.page-template-page-kontakt .contact-text {
  @include mq($from:mobile) {
    @include column(1/1);
  }
  @include mq($from:desktop) {
    @include column(1/1, $gutter:8);
  }
}
/*test*/

.page-template-page-kontakt .contact-details-top {
  width: 70%;
  float: none;
  display: inline-block;
  margin: 0 auto;
  background:url(../images/topografi-trans.jpg) no-repeat;

  padding:40px;
  margin: 0 15%;
  @include rem(font-size, 17px);

}

.page-template-page-kontakt .contact-details-top .contact-form,
.page-template-page-kontakt .contact-details-top .contact-info { @include column(1/1); }
.page-template-page-kontakt .contact-details-top .contact-pic img {
  @include mq($from:mobile) {
    @include center(200px);
  }
 @include mq($from:desktop) {
    @include center(60%);
  }
}
.page-template-page-kontakt .contact-details-top .contact-bar .contact-info .contact-name {
  font-weight: normal;
}
.page-template-page-kontakt .contact-details-top .contact-bar .contact-info {
  width:100%;
  float: left;
}
.page-template-page-kontakt .contact-details-top .contact-bar {
  width: 50%;
  float: left;
  @include mq($until:tablet) {
    width: 100%;
    float: none;
  }
}
.page-template-page-kontakt .contact-details-top .contact-bar .contact-info div { margin-bottom: 8px; }
.page-template-page-kontakt .contact-details-top .contact-form input { margin-bottom:10px; }
.form-style {
    margin: $margin 0 0 0;
}
.form-style > div {
    padding: 0;
    display: block;
    list-style: none;
}

.form-style input, .form-style textarea, .form-style select{

    border:1px solid #d3d3d3;
    background:#f5f5f5;
    padding: 15px;
    margin:0px 0px 15px 0px;
    transition: all 0.30s ease-in-out;
    outline: none;
    width:100%;
}



.page-template-page-kontakt .contact-details {
  background:$darkgrey url(../images/topografi2.jpg);
  color:white;
  padding:40px;
  @include rem(font-size, 17px);
  @include mq($from:mobile) {
    @include column(1/1);
  }
  @include mq($from:desktop) {
    @include column(2/5, $gutter:8);
  }
}
.page-template-page-kontakt .contact-details .contact-form,
.page-template-page-kontakt .contact-details .contact-info { @include column(1/1); }
.page-template-page-kontakt .contact-details .contact-pic img {
  @include mq($from:mobile) {
    @include center(200px);
  }
 @include mq($from:desktop) {
    @include center(60%);
  }
}
.page-template-page-kontakt .contact-bar .contact-info div { margin:0; }
.page-template-page-kontakt .contact-details .contact-form input { margin-bottom:10px; }
.form-style {
    margin: $margin 0 0 0;
}
.form-style > div {
    padding: 0;
    display: block;
    list-style: none;
}
.page-template-page-kontakt .contact-details a { color:white;  }
.form-style input, .form-style textarea, .form-style select{

    border:1px solid #d3d3d3;
    background:#f5f5f5;
    padding: 15px;
    margin:0px 0px 15px 0px;
    transition: all 0.30s ease-in-out;
    outline: none;
    width:100%;
}
.form-style input:focus, .form-style textarea:focus {
    border: 1px solid #888888;
    background:white;
}
.form-style .field-divided{
    width: 49%;
}

.form-style input[type=submit], .form-style input[type=button]{
    background: $yellow;
    padding: 16px 20px 16px 20px;
    border: none;
    color: $darkbrown;
    cursor:pointer;
}
.form-style input[type=submit]:hover, .form-style input[type=button]:hover{
    background: #f4cd1a;
    box-shadow:none;
}
.footer-outer input {
  width:inherit;
  border: 1px solid white;
  @include mq($until:tablet) {
    display: block; clear: both; width: 100%; margin-bottom:5px;
  }
}

.footer-outer input:focus, .footer-outer textarea:focus {
    border: 1px solid white;
    background:white;
}

.footer-outer {  background:$darkgrey url(../images/topografi2.jpg); color:$beige;
  @include mq($from:mobile) {
    padding:30px 0 0 0;
    margin-top:0;
  }
  @include mq($from:desktop) {
    margin-top:-30px;
    padding:100px 0 0px 0;
  }
}
.footer-outer h4 { color:white;
  @include mq($from:mobile) {
  margin-bottom:10px;
  }
  @include mq($from:desktop) {
    margin-bottom:$margin;
    margin-top:$margin;
  }
}
.footer-outer a { color:white;  }
.footer-outer .footer-inner {
  @include mq($from:mobile) {
    @include rem(font-size, 15px);
  }
  @include mq($from:desktop) {
    @include rem(font-size, 17px);
  }
}
.footer-outer .footer-bottom {  @include center(900px);
  @include mq($from:mobile) {
    margin-top:0;
    margin-bottom:20px;
  }
  @include mq($from:desktop) {
    margin-top:60px;
  }
}
.footer-outer .footer-bottom .footer-logo img {  @include center(150px); }
.footer-outer .footer-bottom .footer-menu ul { display: inline-block; }
.footer-outer .footer-bottom .footer-menu ul li {

    width:auto;
    float:left;
    margin-right:15px;

}
.footer-outer .footer-bottom .footer-menu ul li a { @include rem(font-size, 14px); text-decoration: none; text-transform: uppercase; }
.footer-outer .footer-bottom .footer-menu {  padding-top:30px; margin-top:0; text-align: center;
  @include mq($from:mobile) {
    display:none;
  }
  @include mq($from:desktop) {
    display:block;
  }
}

.footer-facebook {
  text-align: center;
  margin-top: 13px;
}

.page-template-page-kontakt .inner-contect {
  margin: 0 auto;
}

.fjuz { text-align:center; @include rem(font-size, 14px); padding-top:30px; margin-top:30px; border-top:#696660 1px solid; }
.page-template-page-kontakt .footer-outer { padding: 50px 0 0; }
#slideout {
    background: $blue;
    position: fixed;
    width: 550px;
    min-height: 283px;
    height:auto;
    top: 35%;
    right:-495px;
    z-index:1999;
}

#open { width:10%; height:100%; float:left; color:white; cursor:pointer; }
#slidecontent { width:90%; float:left; height:100%;  }
#slidecontent .newsletter { height:100%; @include rem(font-size, 17px); padding:30px; color:white; line-height:1.45em; }

#slidecontent .newsletter .form-style input[type=submit], .form-style input[type=button]{
    background: #0582d0;
    padding: 16px 20px 16px 20px;
    border: none;
    color: white;
    cursor:pointer;
}
#slidecontent .newsletter .form-style input[type=submit]:hover, .form-style input[type=button]:hover{
    background: #0073bb;
    box-shadow:none;
}
#slidecontent .newsletter .form-style input { width:inherit; border: 1px solid white; }
#slidecontent .newsletter input:focus, #slidecontent .newsletter textarea:focus {
    border: 1px solid white;
    background:white;
}
.col_1 { @include column(1/1); }
.col_2 {
  @include mq($from:mobile) {
    @include column(1/1, $cycle:1);
  }
  @include mq($from:tablet) {
    @include column(1/2, $cycle:2);
  }
 }
.col_3 {
  @include mq($from:mobile) {
    @include column(1/1);

  }
  /*@include mq($from:tablet) {
    @include column(1/2, $cycle:2);

  }*/
  @include mq($from:desktop) {
    @include column(1/2, $cycle:2, $gutter:4);
  }
  @include mq($from:wide) {
    @include column(1/3, $cycle:3, $gutter:4);
  }
}

.col_4 {
  @include mq($from:mobile) {
    @include column(1/2, $cycle:2);
  }
  @include mq($from:tablet) {
    @include column(1/4, $cycle:4)
  }

}

.hamburger {
  outline:none;
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  height:100%;/*denne er min*/
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 2px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

table.dataTable thead .sorting_asc { background-image: url(../images/sort_asc.png) !important; }
table.dataTable thead .sorting_desc { background-image: url(../images/sort_desc.png) !important; }
table.dataTable thead .sorting { background-image: url(../images/sort_both.png) !important; }
table.dataTable thead th, table.dataTable thead td { border:0 !important; }


    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before, table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
        top: 7px !important;
        left: 4px;
        height: 28px !important;
        width: 28px !important;
        display: block;
        position: absolute;
        color: white;
        font-weight: 600;
        border: none !important;
        border-radius: 14px;
        box-shadow: 0 0 3px #444;
        box-sizing: content-box;
        text-align: center;
        text-indent: 0 !important;
        line-height: 28px !important;
        content: '+';
        background-color: $darkbrown !important;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child, table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child {
        padding-left: 50px !important;
    }

    table.dataTable.order-column tbody tr>.sorting_1, table.dataTable.order-column tbody tr>.sorting_2, table.dataTable.order-column tbody tr>.sorting_3, table.dataTable.display tbody tr>.sorting_1, table.dataTable.display tbody tr>.sorting_2, table.dataTable.display tbody tr>.sorting_3 {
         background-color: transparent !important;
    }

    table.dataTable.display tbody tr.even>.sorting_1, table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
        background-color: transparent !important;
    }

    table.dataTable thead th, table.dataTable tfoot th {
        font-weight: 300 !important;
    }
tbody ul li { background:none; }
table.dataTable>tbody>tr.child ul.dtr-details>li { border:none !important; margin:0; padding:0; }
.wpcf7-mail-sent-ok { border: none !important; background:#757e61 !important; color:white !important; }
.wpcf7-validation-errors { border: none !important; background:tomato !important; color:white !important; }
.wpcf7-response-output {  padding:15px !important;
    @include mq($from:mobile) {
      margin:$margin 0 $margin 0 !important;
    }
    @include mq($from:desktop) {
     margin:$margin 0 0 0 !important;
    }
}
.wpcf7-not-valid-tip { margin:5px 0 10px 0 !important; display:block !important; color:tomato !important; }
#slideout .wpcf7-mail-sent-ok { background:#1577d1 !important;  }
.reservation-wrapper { @include center(900px); }
.demo-form {
    @include mq($from:mobile) {
      @include stack();
    }
        @include mq($from:desktop) {
     @include column(1/2, $cycle:2);


    }
   }
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
.demo-form-wrapper input[type="submit"] { margin-bottom:0 !important; }
.demo-form-full { display:block; clear:both; /*padding-top:25px;*/ }

.demo-row { @include clearfix(); margin-bottom:30px;  }
.demo-form-full input[type="checkbox"] { width:auto; }
.demo-form-full .wpcf7-list-item-label { cursor:pointer; }
.wpcf7-list-item { margin:0 0 0 3px !important; }
.container, .container_sub { line-height: 1.5em;}

.email { border:#c5c5c5 solid 1px; }
.email:hover, .email:active, .email:focus { background:white; border:black solid 1px;  }

.gfield_required { color: red !important; }


::-webkit-input-placeholder {
   color: black;
   @include rem(font-size, 12px);
}

#search::-webkit-input-placeholder {
   color: black;
   @include rem(font-size, 12px);
}
//map start




#floating-panel {
  margin-left: 15px;
  position: absolute;
  z-index: 999;
  margin-top: 10px;
  background-color: #f3d549;
  padding: 5px;
  border: 0 solid #999;
  text-align: left;
  font-family: Roboto,"sans-serif";
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 2px;
  width: auto;
  display: inline-block;

  @include mq($until:wide) {
    left: 20px;
    margin-top:20px;
  }
  @include mq($until:tablet) {
    position:inherit;
    left: 0px;
    right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top:0px;
    width: 100%;
  }

  select#end  {
  display: none;
  }

  select option {
  color: #424146;
  background: #ffffff;
  }

  select {
  border: none;
  background: transparent;
  /*background-color: blue;*/
  width: 200px;
  padding-top: 0px;
  background-size: 20px;
  color: #fff;
  &:focus {
    outline-color: $darkbrown;
  }
  }
  #left {
    float: none;
    margin-bottom: 1px;
    @include mq($until:wide) {
      float: none;
      display: block;

    }
    #start {
      background: #f3d549;
      font-size: 17px;
      color: $darkbrown;
      padding: 10px;
      @include mq($until:tablet) {
        width: 97%;
      }
    }
  }
  #right {
    float: none;
    @include mq($until:wide) {
      float: none;
    }
    .innhold {
      padding: 0 7px;
    }
    p {
      margin-bottom: 0px;
      padding: 0 5px;
      font-family: 'Roboto','sans-serif';
      margin-top: 1px;
      font-weight: normal;
      color: $darkbrown;

      @include mq($until:tablet) {
        padding: 0 7px;
      }
    }
  }
}
.gm-svpc,
.gm-style-mtc,
.gm-style-cc {
  display: none;
}

#map {
  height: 800px !important;
  @include mq($until:tablet) {
    height: 500px !important;
  }
}

.contact-info {
  .bilde {
    width: 30%;
    @include mq($until:desktop) {
      width: 100%;
      display: flex;
    }

    img {
      border-radius: 170px;
      float: left;
      margin-top: 15px;
      @include mq($until:desktop) {
        max-width: 130px;
        margin: 0 auto;
      }
    }
  }
  .informasjon {
    padding-left: 20px;
    padding-top: 10px;
    display: block;
    width: auto;
    float: left;
    @include mq($until:desktop) {
      padding-left: 0;
      width: 100%;
    }
  }
}
